
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import operating from '@/mixins/support/operating.js'

import StartForm from '@/components/chat/StartForm'
import TicketForm from '@/components/chat/TicketForm'

export default {
  components: {
    StartForm,
    TicketForm
  },

  mixins: [
    clickaway,
    operating
  ],

  data () {
    return {
      modal: {
        end: {
          active: false,
          ending: false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      chat: 'chat/chat',
      country: 'country',
      support: 'support/support'
    }),

    sendMessageIsValid () {
      return this.message.length > 0
    },

    message: {
      get () {
        return this.chat.message.value
      },
      set (value) {
        this.$store.dispatch('chat/setMessage', value)
      }
    }
  },

  mounted () {
    this.scrollToBottom()

    this.$echo.channel(`chats.${this.chat.id}.client`)
      .listen('.support.chat.message.created', (e) => {
        this.$store.dispatch('chat/pushMessage', e.data)

        this.$nextTick(() => {
          this.scrollToBottom()
        })

        this.focus()

        Notification.requestPermission().then((status) => {
          if (status === 'granted') {
            const notification = new Notification(this.$t('websockets.chats.messages.created.title', { name: this.chat.name.value }), {
              icon: `${this.$config.appUrl}/favicon.ico`,
              body: this.$t('websockets.chats.messages.created.body', { text: e.data.text })
              // tag: 'chats'
            })

            console.log(notification)
          }
        })
      })
  },

  beforeDestroy () {
    this.$echo.leave(`chats.${this.chat.id}.client`)
  },

  methods: {
    end () {
      this.modal.end.ending = true

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/support/chats/${this.chat.id}/end`, {})
        .then((res) => {
          this.$store.dispatch('chat/endChat')
          localStorage.removeItem('chat')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.modal.end.active = false
          this.modal.end.ending = false
        })
    },

    close () {
      this.$store.dispatch('support/setActive', true)
      this.$store.dispatch('chat/setActive', false)
    },

    typing (e) {
      if (e.keyCode === 13/* enter key */) {
        e.preventDefault()
        e.stopPropagation()

        this.send({
          text: this.message,
          from: 'user'
        })

        return
      }
    },

    scrollToBottom () {
      if (this.chat.id === null) {
        return
      }

      const container = this.$refs['chat-messages']

      container.scrollTop = container.scrollHeight
    },

    focus () {
      this.$nextTick(() => {
        console.log(this.$refs.message)
        // this.$refs.message.$el.focus()
      })
    },

    started () {
      this.send({
        text: this.$t('support.chat.system.operator.awaiting'),
        from: 'system'
      })

      this.$nextTick(() => {
        this.scrollToBottom()
        this.focus()
      })
    },

    send (message) {
      if (message.text.length === 0) {
        return
      }

      this.$store.dispatch('chat/setMessageSending', true)

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/support/chats/${this.chat.id}/messages`, message)
        .then((res) => {
          this.$store.dispatch('chat/pushMessage', res.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.message = ''
          this.$store.dispatch('chat/setMessageSending', false)
          this.scrollToBottom()
        })
    }
  }
}
